/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong",
    a: "a",
    h2: "h2",
    div: "div",
    img: "img",
    h3: "h3",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {HrefLangManager, TableOfContents, LandingPageCta, SideBySide, MapInfo, ThreeColCta} = _components;
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!MapInfo) _missingMdxReference("MapInfo", true);
  if (!SideBySide) _missingMdxReference("SideBySide", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  if (!ThreeColCta) _missingMdxReference("ThreeColCta", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/hearing-aids/types/bte/'], ['en', 'https://www.hear.com/hearing-aids/types/bte/'], ['en-US', 'https://www.hear.com/hearing-aids/types/bte/'], ['en-CA', 'https://ca.hear.com/hearing-aids/types/bte/']]
  }), "\n", React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Behind-the-ear hearing aids (BTE)"), " are the best known and most frequently selected models. The ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "hearing aids"), " are light, comfortable, and can be worn almost invisibly behind the ear. Behind-the-ear hearing aids are suitable for almost all types of hearing loss. Around 90% of American hearing aid wearers choose behind-the-ear hearing aids. In general the differentiation is between In-the-ear hearing aids and Behind-the-ear hearing aids. Certain levels of hearing loss are depending on a BTE model and cannot use a ITE (in-the-ear) hearing aid. Finding the suitable ", React.createElement(_components.a, {
    href: "/hearing-aids/types/",
    className: "c-md-a"
  }, "type of hearing aid"), " is very important and can make all the difference. Our hearing professionals will gladly consult you on the best individual fit for your better hearing."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Over ear hearing aids"), " are available in various colors to match the wearer’s hair and skin color or to express his or her personal style. They can also come with additional functions. Generally, the larger the hearing aid, the higher the efficiency."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "While small BTE hearing aids are adequate for most types of hearing impairment, severe hearing loss requires larger behind-the-ear hearing aids."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "bte-hearing-aids-with-receiver-in-canal",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#bte-hearing-aids-with-receiver-in-canal",
    "aria-label": "bte hearing aids with receiver in canal permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "BTE hearing aids with receiver in canal"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "These over ear hearing aids are very popular with first-time buyers. They are the most unobtrusive form of BTE models and are characterized by a high degree of wearing comfort. The amplified signal is transferred to the ‘receiver’ through a thin cable. The receiver is located in the ear canal just in front of the eardrum, i.e. ‘externally’ (outside the hearing aid housing). A small fixation dome or an individual ear mold supports the receiver’s position."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "This design delivers a clear and natural quality of speech – your own voice and ambient sounds are perceived naturally again. An advantage is that the ear is only closed partially and the ear canal remains pleasantly free and open, thereby permitting good ventilation. The fact that a thin audio tube creates a direct connection between the hearing aid and eardrum also ensures a particularly high quality of sound. This model, also called “open supply,” can be used in slight and moderate cases of hearing loss."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/bte-ha-system.jpg",
    alt: "Behind the ear hearing aids with external receiver",
    className: "c-md-img"
  })), "\n", React.createElement(LandingPageCta, {
    copy: "Try hearing aids now",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "bte-hearing-aids-with-sound-tube",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#bte-hearing-aids-with-sound-tube",
    "aria-label": "bte hearing aids with sound tube permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "BTE hearing aids with sound tube"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "These hearing aids are commonly used for more severe hearing loss. They offer pleasant wearing comfort and provide very high performance. The sound travels through a tube into an individual ear mold, which is custom fitted to the wearer’s ear. The housing, larger when compared to the BTE hearing aid with an external receiver, contains a more efficient amplifier and a larger battery. These days, extensive combinations of color and form are part of the modern, unobtrusive hearing systems, even in this high-performance range."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "In comparison to in-the-ear hearing aids, behind-the-ear devices tend to offer greater wearing comfort, better natural speech and sound perception, as well as easier operation."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/BTE_with_sound_tube-frame.jpg",
    alt: "Behind the ear hearing aids with sound tube",
    className: "c-md-img"
  })), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "top-behind-the-ear-hearing-aid-recommendation",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#top-behind-the-ear-hearing-aid-recommendation",
    "aria-label": "top behind the ear hearing aid recommendation permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Top Behind the ear hearing aid recommendation"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/go-ix-hearing-aids-box-black.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "hearcom-horizon-go-ix",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearcom-horizon-go-ix",
    "aria-label": "hearcom horizon go ix permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "hear.com Horizon Go IX"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "The Horizon Go IX hearing aids by ", React.createElement(_components.a, {
    href: "/",
    className: "c-md-a"
  }, "hear.com"), " is designed for active individuals who want top-notch hearing that lasts throughout the day. Its exceptional battery life keeps you connected without the need to change tiny batteries, making it easy to stay connected on the go. The Horizon line of hearing aids by hear.com aims to enhance hearing performance."), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Learn more about the ", React.createElement(_components.a, {
    href: "/hearing-aids/horizon/go-ix/",
    className: "c-md-a"
  }, "Horizon Go IX hearing aid"))), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "pros-and-cons-of-behind-the-ear-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#pros-and-cons-of-behind-the-ear-hearing-aids",
    "aria-label": "pros and cons of behind the ear hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Pros and Cons of behind the ear hearing aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Behind-the-ear (BTE) hearing aids have both advantages and disadvantages:"), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "pros-of-bte-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#pros-of-bte-hearing-aids",
    "aria-label": "pros of bte hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Pros of BTE hearing aids"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Comfortable:"), " They are often more comfortable to wear since the bulk of the device sits behind the ear, with only a small component in the ear canal."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Durability:"), " BTE hearing aids are typically more durable and less prone to moisture damage compared to smaller, in-the-ear styles."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Battery Life:"), " They can accommodate larger batteries, which generally results in longer battery life."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Powerful:"), " BTE models can provide more amplification power, making them suitable for individuals with severe to profound hearing loss."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Easier Maintenance:"), " They are easier to handle and maintain due to their larger size, which can be beneficial for those with dexterity issues."), "\n"), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "cons-of-bte-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#cons-of-bte-hearing-aids",
    "aria-label": "cons of bte hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Cons of BTE hearing aids"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Visibility:"), " Despite modern designs being smaller and more discreet, BTE hearing aids are still more visible compared to completely-in-the-canal (CIC) or invisible-in-canal (IIC) styles."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Cosmetic Concerns:"), " They may not meet aesthetic preferences for those who prefer a more discreet hearing aid option."), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "expert-hearing-aids-care-near-you",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#expert-hearing-aids-care-near-you",
    "aria-label": "expert hearing aids care near you permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Expert hearing aids care near you"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "You shouldn’t have to go far for quality hearing care. Our expert audiologists and hearing experts are conveniently located nearby, right in your own neighborhood. It is our promise that you’ll only work with the top 2% of hearing professionals in the country who can guarantee your best result. Keep in mind that the Horizon Go IX hearing aids must be programmed by a professional to make a real difference in your life. We can’t wait to meet you!"), "\n", React.createElement(MapInfo), "\n", React.createElement(LandingPageCta, {
    copy: "Get in touch with an Audiologist",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "resources",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#resources",
    "aria-label": "resources permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Resources"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Learn everything you need to know about hearing aids and hearing loss."), "\n", React.createElement(ThreeColCta, {
    ImgSrc1: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/women-with-tablet.jpg",
    ContentTitle1: "Hearing Test",
    ContentCopy1: "Check how is your hearing in less than 5 minutes.",
    Url1: "/hearing-test/",
    ImgSrc2: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-aids-behind-ear-blur.jpg",
    ContentTitle2: "Value & Price",
    ContentCopy2: "Are hearing aids worth the cost? We asked an expert.",
    Url2: "/hearing-aids/prices/",
    ImgSrc3: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/go-ax-grey-blur.jpg",
    ContentTitle3: "Hearing Aids",
    ContentCopy3: "Connect you to the world around you with our wide range of devices.",
    Url3: "/hearing-aids/"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
